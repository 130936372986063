import { createSlug } from '../../../../../util/urlHelpers';
import getCountryCodes from '../../../../../translations/countryCodes';

function getFirstNumberAndSpace(str) {
  if (!str) return null;
  const match = str.match(/^\d+\s/);
  return match ? match[0] : null;
}

const getRatingReviews = (location) => {
  const reviews = location?.reviews;
  let finalReviews = 0;
  const reviewsCount = reviews.length;

  for (const review of reviews) {
    finalReviews += review.rating;
  }

  finalReviews = finalReviews / reviewsCount;

  return finalReviews;
}

export const formatListing = (listing, currentLanguage) => {
  const {
    finalReviews,
    experienceTitleDe,
    experienceTitle,
    stayTitleDe,
    stayTitle,
    listingType,
    city,
    country,
    category,
    MDE,
    location,
  } = listing?.data?.attributes?.publicData || {};

  const fallbackTitle = listing?.data?.attributes?.title;
  const slug = createSlug(listing?.data?.attributes?.title);
  const listingId = listing?.data?.id?.uuid;
  const listingLink = `${currentLanguage}/l/${slug}/${listingId}`;
  const price = Number(Number(listing?.data?.attributes?.price?.amount) / 100).toFixed();
  const listingPrice = ` ${price} €`;
  const countryNameEn = getCountryCodes('en').find(c => c.code == country)?.name ?? '';
  const countryNameDe = getCountryCodes('de').find(c => c.code == country)?.name ?? '';
  const listingLocation = city + ', ' + countryNameEn;
  const listingLocationDe = city + ', ' + countryNameDe;
  const title = listingType == 'impact-experience' ? experienceTitle : stayTitle;
  const titleDe = listingType == 'impact-experience' ? experienceTitleDe : stayTitleDe;
  const mde = getFirstNumberAndSpace(MDE);
  const stayPriceRange = mde ? `${mde} Days from ${price}€` : null;
  const stayPriceRangeDe = mde ? `${mde} Tage ab ${price}€` : null;

  return {
    link: listingLink,
    linkName: 'ListingPage',
    linkParams: {
      slug,
      id: listingId,
    },
    name: title ?? fallbackTitle,
    name_de: titleDe ?? fallbackTitle,
    price: listingPrice,
    projectType: category,
    location: listingLocation,
    location_de: listingLocationDe,
    image: listing?.included[2]?.attributes?.variants['listing-card']?.url ?? '',
    finalReviews: finalReviews || getRatingReviews(location),
    isExperience: listingType == 'impact-experience',
    stayPriceRange,
    stayPriceRangeDe,
  };
};
