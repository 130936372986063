export const multiDayCategoryDataKeys = [
  {
    id: '66c5a193-f9ff-44f7-a963-8ac28ae831b2',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '66d5c489-abd8-4aee-8755-28b6839821dc',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '67a0e9f5-37e3-451b-a4f4-43b65a10bdc9',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '67617fe2-3262-456e-9b43-4bb237fac795',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '66c59c1a-426b-49f9-bd4a-5fc994ef33ca',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '67265969-f137-479c-aad1-b0ae24687d47',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '67289723-8c8b-4166-b4e6-d2a4978da7a8',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '676192bd-78a4-4a05-b9b8-60cfa7dc2a8e',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '66c5a193-f9ff-44f7-a963-8ac28ae831b2',
    projectType: [],
    location: 'europe',
  },
  {
    id: '66d5c489-abd8-4aee-8755-28b6839821dc',
    projectType: [],
    location: 'europe',
  },
  {
    id: '676192bd-78a4-4a05-b9b8-60cfa7dc2a8e',
    projectType: [],
    location: 'europe',
  },
  {
    id: '67a0e9f5-37e3-451b-a4f4-43b65a10bdc9',
    projectType: [],
    location: 'europe',
  },
  {
    id: '67617fe2-3262-456e-9b43-4bb237fac795',
    projectType: [],
    location: 'europe',
  },
  {
    id: '67289723-8c8b-4166-b4e6-d2a4978da7a8',
    projectType: [],
    location: 'europe',
  },
  {
    id: '67264aea-a026-47e9-8c1d-9629b4b6d401',
    projectType: [],
    location: 'europe',
  },
  {
    id: '6728cde0-a28c-4f9b-9607-07c7bc3497b8',
    projectType: [],
    location: 'europe',
  },
  {
    id: '66f526b2-7287-4919-a2f8-2c1e10b4ef41',
    projectType: [],
    location: 'asia',
  },
  {
    id: '67265969-f137-479c-aad1-b0ae24687d47',
    projectType: [],
    location: 'asia',
  },
  {
    id: '66f4d18c-0f58-46dc-a12a-b0dc31fa407a',
    projectType: [],
    location: 'asia',
  },
  {
    id: '67dd6157-263a-453a-ac2c-ee340da5490f',
    projectType: [],
    location: 'asia',
  },
  {
    id: '673ed6af-4f43-4452-b343-da4fe1a2b5f0',
    projectType: [],
    location: 'asia',
  },
  {
    id: '67252b03-3da7-4fd6-b26c-2ebb07cc8884',
    projectType: [],
    location: 'asia',
  },
  {
    id: '67618aaa-6dbb-4511-8c7c-4aaaa27264f4',
    projectType: [],
    location: 'asia',
  },
  {
    id: '674d253d-a0fa-49ad-9416-14dce8bd1dd2',
    projectType: [],
    location: 'asia',
  },
  {
    id: '66c59c1a-426b-49f9-bd4a-5fc994ef33ca',
    projectType: [],
    location: 'south-america',
  },
  {
    id: '672f71e9-9d05-4727-9343-1c91a57e5d07',
    projectType: [],
    location: 'south-america',
  },
  {
    id: '66d71874-6fdd-4efb-898c-3406682e4100',
    projectType: [],
    location: 'south-america',
  },
  {
    id: '67618765-2808-47e8-8c8a-f4c5aab39a36',
    projectType: [],
    location: 'south-america',
  },
  {
    id: '672d33fd-8327-4879-ac4c-33d4fd1a8e56',
    projectType: [],
    location: 'south-america',
  },
  {
    id: '672f7e7d-5ad4-4362-b882-3291ac97f686',
    projectType: [],
    location: 'south-america',
  },
  {
    id: '6732354c-eb18-465a-a00d-79b154c08104',
    projectType: [],
    location: 'south-america',
  },
  {
    id: '66e451ff-93b1-4c9b-869d-262e3b8e65bd',
    projectType: [],
    location: 'south-america',
  },
  {
    id: '67a0e9f5-37e3-451b-a4f4-43b65a10bdc9',
    projectType: ['animal'],
    location: '',
  },
  {
    id: '67252eff-3725-4da2-819f-bb5979badb44',
    projectType: ['animal'],
    location: '',
  },
  {
    id: '674d253d-a0fa-49ad-9416-14dce8bd1dd2',
    projectType: ['animal'],
    location: '',
  },
  {
    id: '674ee454-1c84-43eb-945c-d9323b13236a',
    projectType: ['animal'],
    location: '',
  },
  {
    id: '67265415-f122-4645-abb1-11304128cd00',
    projectType: ['animal'],
    location: '',
  },
  {
    id: '673ed6af-4f43-4452-b343-da4fe1a2b5f0',
    projectType: ['animal'],
    location: '',
  },
  {
    id: '66c5a193-f9ff-44f7-a963-8ac28ae831b2',
    projectType: ['animal'],
    location: '',
  },
  {
    id: '672dfacb-896b-4b69-ad23-571f2e990fdb',
    projectType: ['animal'],
    location: '',
  },
  {
    id: '66c5a193-f9ff-44f7-a963-8ac28ae831b2',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '66f3cbad-bc9e-47af-b64f-786c466a3914',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '674ee454-1c84-43eb-945c-d9323b13236a',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '676514dd-3dc5-4bdb-854b-d7b463506e51',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '66c59c1a-426b-49f9-bd4a-5fc994ef33ca',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '67615bda-2d9a-4b0f-b44d-6f837bc92f29',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '672f71e9-9d05-4727-9343-1c91a57e5d07',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '66e451ff-93b1-4c9b-869d-262e3b8e65bd',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '67618aaa-6dbb-4511-8c7c-4aaaa27264f4',
    projectType: ['active'],
    location: '',
  },
  {
    id: '66d5c489-abd8-4aee-8755-28b6839821dc',
    projectType: ['active'],
    location: '',
  },
  {
    id: '6761613c-e49f-44c1-87cc-a50b2649dc53',
    projectType: ['active'],
    location: '',
  },
  {
    id: '67a0e9f5-37e3-451b-a4f4-43b65a10bdc9',
    projectType: ['active'],
    location: '',
  },
  {
    id: '67619341-40e0-4e40-aaef-8cb1cef5d485',
    projectType: ['active'],
    location: '',
  },
  {
    id: '672d33fd-8327-4879-ac4c-33d4fd1a8e56',
    projectType: ['active'],
    location: '',
  },
  {
    id: '672dfacb-896b-4b69-ad23-571f2e990fdb',
    projectType: ['active'],
    location: '',
  },
  {
    id: '66f3cbad-bc9e-47af-b64f-786c466a3914',
    projectType: ['active'],
    location: '',
  },
  {
    id: '67619081-3913-45f5-af6e-fa61b2f8dc1f',
    projectType: ['culture'],
    location: '',
  },
  {
    id: '67177961-927a-474b-8cdc-6967c0c3febb',
    projectType: ['culture'],
    location: '',
  },
  {
    id: '67264aea-a026-47e9-8c1d-9629b4b6d401',
    projectType: ['culture'],
    location: '',
  },
  {
    id: '6728b183-28e0-4c40-951f-bfaca996d54e',
    projectType: ['culture'],
    location: '',
  },
  {
    id: '6717540c-a492-4f19-846a-3e5cedc925eb',
    projectType: ['culture'],
    location: '',
  },
  {
    id: '66c59c1a-426b-49f9-bd4a-5fc994ef33ca',
    projectType: ['culture'],
    location: '',
  },
  {
    id: '672f7e7d-5ad4-4362-b882-3291ac97f686',
    projectType: ['culture'],
    location: '',
  },
  {
    id: '67618765-2808-47e8-8c8a-f4c5aab39a36',
    projectType: ['culture'],
    location: '',
  },
];

export const uniqueAccomodationCategoryKeys = [
  {
    id: '64d56d78-7c8e-4b2e-9398-a90e6edb40be',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '62ba9f2d-f348-4467-9d82-ed33b970644d',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '67054f87-f314-4ca0-ac1f-d7e8449ab102',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '62a099e0-2f16-4714-aad6-4e6d38da4185',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '606c6f7f-54c3-461e-a711-0ffcd2e19bc7',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '61027bc1-cffc-4b23-bf8d-c0763b67cd3c',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '63eabf21-34e1-4e52-bc90-3e812f2aee00',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '60e462cb-1cd7-469a-b724-8c7ad5713ad5',
    projectType: ['stays'],
    location: '',
  },
  {
    id: '63eabf21-34e1-4e52-bc90-3e812f2aee00',
    projectType: [''],
    location: 'italy',
  },
  {
    id: '6233ba15-0b00-42a1-9992-d46b03f5196d',
    projectType: [''],
    location: 'italy',
  },
  {
    id: '65b260e0-46a3-40ba-9f84-c52fcadede57',
    projectType: [''],
    location: 'italy',
  },
  {
    id: '63e10db5-5aec-454c-a271-60a52e044141',
    projectType: [''],
    location: 'italy',
  },
  {
    id: '67054f87-f314-4ca0-ac1f-d7e8449ab102',
    projectType: [''],
    location: 'italy',
  },
  {
    id: '624e9b3e-8ff3-40d4-bd7b-de7c590856d1',
    projectType: [''],
    location: 'italy',
  },
  {
    id: '64d8879e-75c2-430f-a98c-c3ae632992e9',
    projectType: [''],
    location: 'italy',
  },
  {
    id: '6527b6a3-2846-4750-8f1b-b3e0ddcb3009',
    projectType: [''],
    location: 'italy',
  },
  {
    id: '6405e21f-39e0-4971-9acb-9797d9057c78',
    projectType: [''],
    location: 'spain',
  },
  {
    id: '606c6f7d-012d-41cb-bd14-5faa5c89739c',
    projectType: [''],
    location: 'spain',
  },
  {
    id: '637b84b3-24f6-457d-b369-795fb9f9206c',
    projectType: [''],
    location: 'spain',
  },
  {
    id: '6260fe27-cd80-42e8-8f46-7924f7ec555a',
    projectType: [''],
    location: 'spain',
  },
  {
    id: '6390d65a-3f01-4752-b05b-f52df834a112',
    projectType: [''],
    location: 'spain',
  },
  {
    id: '65284e68-a0d7-4079-9ebd-51445e20744e',
    projectType: [''],
    location: 'spain',
  },
  {
    id: '63092f17-0285-4456-8c0c-40acc84e8efe',
    projectType: [''],
    location: 'spain',
  },
  {
    id: '63dfaddd-f783-42aa-ab8e-80c851756e39',
    projectType: [''],
    location: 'spain',
  },
  {
    id: '65a6fd8a-68c5-41bb-bc71-bccdd0716988',
    projectType: [''],
    location: 'colombia',
  },
  {
    id: '64d56d78-7c8e-4b2e-9398-a90e6edb40be',
    projectType: [''],
    location: 'colombia',
  },
  {
    id: '62337420-e9af-43f0-98d3-8efd7edbe433',
    projectType: [''],
    location: 'colombia',
  },
  {
    id: '62824e8f-1520-4b76-8ebf-0b7acd0833be',
    projectType: [''],
    location: 'colombia',
  },
  {
    id: '67432667-fcb4-4241-b7fb-bc4f6d2d8ef3',
    projectType: [''],
    location: 'colombia',
  },
  {
    id: '6617f9c0-afe0-4339-a61f-0d980ed77117',
    projectType: [''],
    location: 'colombia',
  },
  {
    id: '60fe3a9d-e47f-44e9-aeb4-90981cef49cb',
    projectType: [''],
    location: 'colombia',
  },
  {
    id: '65ef2abf-5528-477c-88d9-4aa618a54e8a',
    projectType: [''],
    location: 'colombia',
  },
  {
    id: '66ed642a-6aa7-437b-94eb-405f1747042b',
    projectType: [''],
    location: 'indonesia',
  },
  {
    id: '65fc1944-9b63-4eff-818c-e5bec37f0bec',
    projectType: [''],
    location: 'indonesia',
  },
  {
    id: '633d6100-ca35-428a-81e9-1f89dd128aa6',
    projectType: [''],
    location: 'indonesia',
  },
  {
    id: '63baab8a-16a4-4ba4-820a-f3e1aad15299',
    projectType: [''],
    location: 'indonesia',
  },
  {
    id: '62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    projectType: [''],
    location: 'indonesia',
  },
  {
    id: '63c02289-830e-4058-971e-7dec423998e5',
    projectType: [''],
    location: 'indonesia',
  },
  {
    id: '6319a89b-d28b-4e3e-9c8e-3a3cf34554dd',
    projectType: [''],
    location: 'indonesia',
  },
  {
    id: '62deafa0-5661-4bb7-a058-ea7c1166cf3b',
    projectType: [''],
    location: 'indonesia',
  },
  {
    id: '672d21e6-2806-4249-81ff-24dd56e52f6b',
    projectType: [''],
    location: 'ecuador',
  },
  {
    id: '63a8df6a-2550-4c28-8839-d0b354e53301',
    projectType: [''],
    location: 'ecuador',
  },
  {
    id: '656a50f4-c3d6-4cbb-9188-4fb3a9f1e196',
    projectType: [''],
    location: 'ecuador',
  },
  {
    id: '6560b5d1-44aa-477e-9556-80951e2e09fe',
    projectType: [''],
    location: 'ecuador',
  },
  {
    id: '6065caa6-39a5-438b-a862-7fd72e8943ee',
    projectType: [''],
    location: 'ecuador',
  },
  {
    id: '60ef5b8d-6522-4a26-8f90-59ca26caa873',
    projectType: [''],
    location: 'ecuador',
  },
  {
    id: '62827b0e-6c9b-4d56-a355-7b79a06b0d6f',
    projectType: [''],
    location: 'ecuador',
  },
  {
    id: '64e666f9-9013-4738-838c-9df05bf7900e',
    projectType: [''],
    location: 'ecuador',
  },
  {
    id: '640ece10-c9fe-4722-8c2b-1f5063f8e766',
    projectType: [''],
    location: 'namibia',
  },
  {
    id: '60e462cb-1cd7-469a-b724-8c7ad5713ad5',
    projectType: [''],
    location: 'namibia',
  },
  {
    id: '62c842b0-66d4-4981-811b-44a25a3107cf',
    projectType: [''],
    location: 'namibia',
  },
  {
    id: '62a1ff2e-ba58-4f87-bdc6-c3b3b3b04055',
    projectType: [''],
    location: 'namibia',
  },
  {
    id: '62a0ce34-b8bf-4f85-9365-58bff1a12257',
    projectType: [''],
    location: 'namibia',
  },
  {
    id: '62a1d5b5-b9a8-4374-ae55-a69589aa1f61',
    projectType: [''],
    location: 'namibia',
  },
  {
    id: '66740023-f55d-4328-8dbe-db50bb199dd6',
    projectType: [''],
    location: 'namibia',
  },
  {
    id: '642c5223-51c6-45a6-8b6a-9626e67cd86d',
    projectType: [''],
    location: 'namibia',
  },
  {
    id: '62ba9f2d-f348-4467-9d82-ed33b970644d',
    projectType: ['farm'],
    location: '',
  },
  {
    id: '62948640-5641-4269-8189-2f8dee66343d',
    projectType: ['farm'],
    location: '',
  },
  {
    id: '628e8233-d722-4d54-ada5-2a660f8e9515',
    projectType: ['farm'],
    location: '',
  },
  {
    id: '624b0eb7-42ca-4174-81cb-892f9e72fce5',
    projectType: ['farm'],
    location: '',
  },
  {
    id: '624a91f2-0c05-4b1f-939a-169d8a210e16',
    projectType: ['farm'],
    location: '',
  },
  {
    id: '63eabf21-34e1-4e52-bc90-3e812f2aee00',
    projectType: ['farm'],
    location: '',
  },
  {
    id: '62e38e49-3c21-449d-b910-bf45f3584ef9',
    projectType: ['farm'],
    location: '',
  },
  {
    id: '60be7ff5-e970-4951-a69d-d0b8d2b5d8fb',
    projectType: ['farm'],
    location: '',
  },
  {
    id: '65f34f9e-2975-4931-ab4d-8144eb85686b',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '62ba9f2d-f348-4467-9d82-ed33b970644d',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '63a41e4e-00b4-41e2-b721-45f9a15a0e39',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '62a0ce34-b8bf-4f85-9365-58bff1a12257',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '664c3b0f-449f-4ccb-bc55-59e07c246298',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '63db30ec-883e-4f0f-90f8-2986bb1ecb02',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '63768548-8d40-4b0a-a7c9-54fd8101c7bb',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '65284e68-a0d7-4079-9ebd-51445e20744e',
    projectType: ['nature'],
    location: '',
  },
  {
    id: '624b0eb7-42ca-4174-81cb-892f9e72fce5',
    projectType: ['family'],
    location: '',
  },
  {
    id: '624a91f2-0c05-4b1f-939a-169d8a210e16',
    projectType: ['family'],
    location: '',
  },
  {
    id: '63eabf21-34e1-4e52-bc90-3e812f2aee00',
    projectType: ['family'],
    location: '',
  },
  {
    id: '60be7ff5-e970-4951-a69d-d0b8d2b5d8fb',
    projectType: ['family'],
    location: '',
  },
  {
    id: '64b2571b-ce5b-4f7e-a123-d676bc2db504',
    projectType: ['family'],
    location: '',
  },
  {
    id: '628e8233-d722-4d54-ada5-2a660f8e9515',
    projectType: ['family'],
    location: '',
  },
  {
    id: '629f52b6-3a76-48a8-a278-90f2061461aa',
    projectType: ['family'],
    location: '',
  },
  {
    id: '60f83c13-5508-430b-9eb2-6618883b3038',
    projectType: ['family'],
    location: '',
  },
  {
    id: '6076f135-d5ac-46a0-9694-45f04752505e',
    projectType: ['islands'],
    location: '',
  },
  {
    id: '6319a89b-d28b-4e3e-9c8e-3a3cf34554dd',
    projectType: ['islands'],
    location: '',
  },
  {
    id: '633f1dc7-70ce-4a5e-863f-d5e49cbf9e15',
    projectType: ['islands'],
    location: '',
  },
  {
    id: '623af249-cc7e-4fe4-8223-b825f4a96d71',
    projectType: ['islands'],
    location: '',
  },
  {
    id: '63205ffd-8860-406f-ba26-b212dff41215',
    projectType: ['islands'],
    location: '',
  },
  {
    id: '61027bc1-cffc-4b23-bf8d-c0763b67cd3c',
    projectType: ['islands'],
    location: '',
  },
  {
    id: '62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    projectType: ['islands'],
    location: '',
  },
  {
    id: '62f676a1-3a55-48ff-96a8-bb2e1bbe83f1',
    projectType: ['islands'],
    location: '',
  },
];
